import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';
import { computed } from 'vue';

export function useOrderItemAggregationByMonthWithIntegral(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(ORDER_ITEMS_AGGREGATED_BY_MONTH, variables, () => ({
    enabled: variables.value.retrieveSpecific === false,
  }));

  const orderItemsByMonth = computed(() => (result.value ? result.value.orderItemAggregationNew.nodes ?? [] : []));

  onError((err) => {
    console.error('useOrderItemAggregationByMonthWithIntegral', err);
    error();
  });

  return {
    aggregations: orderItemsByMonth,
    loading,
    refetch,
  };
}

const ORDER_ITEMS_AGGREGATED_BY_MONTH = gql`
  query orderItemAggregatedByMonth(
    $tenantId: ID!
    $fromDate: ISODate
    $toDate: ISODate
    $productId: ID
    $supplierId: ID
  ) {
    orderItemAggregationNew(
      tenantId: $tenantId
      fromDate: $fromDate
      toDate: $toDate
      productId: $productId
      aggregateBy: "month"
      supplierId: $supplierId
    ) {
      nodes {
        product {
          id
          name
        }
        groupedByMonth
        pricedOrderItemAverage
        quantityWithPrice
        sumPrice
      }
    }
  }
`;
