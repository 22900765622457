import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { computed } from 'vue';

function baseUse({ variables, gql, hookName, options, keyToSelectNodes, resolver }) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(gql, variables, options);
  const aggregations = computed(() =>
    keyToSelectNodes ? (result.value ? result.value[keyToSelectNodes].nodes ?? [] : []) : resolver(result)
  );
  onError((err) => {
    console.error(hookName, err);
    error();
  });

  return {
    aggregations,
    loading,
    refetch,
  };
}

export function useProductDataCard(variables) {
  return baseUse({
    variables,
    gql: PRODUCT_DATA_CARD,
    hookName: 'useProductCard',
    options: () => ({
      enabled: variables.value.selectedPeriod !== null,
    }),
    keyToSelectNodes: 'orderItemAggregationNew',
  });
}

export function usePurchaseDataCard(variables) {
  return baseUse({
    variables,
    gql: PURCHASE_DATA_CARD,
    hookName: 'usePurchaseDataCard',
    options: () => ({
      enabled: variables.value.selectedPeriod !== null,
    }),
    keyToSelectNodes: 'orderItemAggregationNew',
  });
}

export function useSupplierDataCard(variables) {
  return baseUse({
    variables,
    gql: SUPPLIER_DATA_CARD,
    hookName: 'useSupplierDataCard',
    options: () => ({
      enabled: variables.value.selectedPeriod !== null,
    }),
    keyToSelectNodes: 'orderItemAggregationNew',
  });
}

export function useProductPurchasePeriod(variables) {
  return baseUse({
    variables,
    gql: PRODUCT_PURCHASE_PERIOD,
    hookName: 'useProductPurchasePeriod',
    resolver: (result) => (result.value ? result.value.orderAggregation.groupOrderItemsNew.nodes ?? [] : []),
  });
}

export function usePriceChangeSlider(variables) {
  return baseUse({
    variables,
    gql: PRICE_CHANGE_SLIDER,
    hookName: 'usePriceChangeSlider',
    options: () => ({
      enabled: !!variables.value.supplierId && !!variables.value.businessId && !!variables.value.productId,
    }),
    keyToSelectNodes: 'orderItemAggregationNew',
  });
}

const PRICE_CHANGE_SLIDER = gql`
  query priceChangeSlider($businessId: ID!, $supplierId: ID, $productId: ID, $fromDate: ISODate, $toDate: ISODate) {
    orderItemAggregationNew(
      tenantId: $businessId
      supplierId: $supplierId
      productId: $productId
      fromDate: $fromDate
      toDate: $toDate
      aggregateBy: "product"
      monthlyAverage: true
    ) {
      nodes {
        pricedOrderItemAverage
        monthlyAverageSumTotalNetPricePerUnit
        monthlyAverageTotalQuantity
      }
    }
  }
`;

const PRODUCT_PURCHASE_PERIOD = gql`
  query orderItemAggregation($businessId: ID!, $productId: ID) {
    orderAggregation {
      groupOrderItemsNew(businessId: $businessId, productId: $productId) {
        nodes {
          firstOrder {
            date
          }
          lastOrder {
            date
          }
        }
      }
    }
  }
`;

const SUPPLIER_DATA_CARD = gql`
  query supplierDataCard(
    $businessId: ID!
    $supplierId: ID
    $productId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $monthlyAverage: Boolean
  ) {
    orderItemAggregationNew(
      tenantId: $businessId
      supplierId: $supplierId
      productId: $productId
      fromDate: $fromDate
      toDate: $toDate
      monthlyAverage: $monthlyAverage
    ) {
      nodes {
        quantity
        numberOfOrders
        numberOfProducts
        sumPrice
        quantityWithPrice
        pricedOrderItemAverage
        monthlyAverageSumTotalNetPricePerUnit
        monthlyAverageTotalQuantity
        monthlyAverageNumberOfOrders
      }
    }
  }
`;

const PURCHASE_DATA_CARD = gql`
  query purchaseDataCard(
    $businessId: ID!
    $supplierId: ID
    $productId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $monthlyAverage: Boolean
  ) {
    orderItemAggregationNew(
      tenantId: $businessId
      supplierId: $supplierId
      productId: $productId
      fromDate: $fromDate
      toDate: $toDate
      monthlyAverage: $monthlyAverage
    ) {
      nodes {
        quantity
        numberOfOrders
        numberOfProducts
        sumPrice
        quantityWithPrice
        pricedOrderItemAverage
        monthlyAverageSumTotalNetPricePerUnit
        monthlyAverageTotalQuantity
        monthlyAverageNumberOfOrders
      }
    }
  }
`;

const PRODUCT_DATA_CARD = gql`
  query productDataCard(
    $businessId: ID!
    $supplierId: ID
    $productId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $monthlyAverage: Boolean
  ) {
    orderItemAggregationNew(
      tenantId: $businessId
      supplierId: $supplierId
      productId: $productId
      fromDate: $fromDate
      toDate: $toDate
      monthlyAverage: $monthlyAverage
    ) {
      nodes {
        quantity
        numberOfOrders
        sumPrice
        quantityWithPrice
        pricedOrderItemAverage
        monthlyAverageSumTotalNetPricePerUnit
        monthlyAverageTotalQuantity
        monthlyAverageNumberOfOrders
      }
    }
  }
`;
