import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { computed } from 'vue';

export function useOrderItems(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(ORDER_ITEMS_QUERY, variables);
  const orderItems = computed(() =>
    result.value && result.value.orderItemsNew
      ? result.value.orderItemsNew.nodes.map((order) => ({
          ...order,
          date: new Date(order.date),
          items: order.items
            ? order.items.map((orderItem) => ({
                ...orderItem,
                orderDate: new Date(orderItem.orderDate),
              }))
            : order.items,
        })) ?? []
      : []
  );

  onError((err) => {
    console.error('useOrderItems', err);
    error();
  });

  return {
    orderItems,
    loading,
    refetch,
  };
}

const ORDER_ITEMS_QUERY = gql`
  query orderItems(
    $businessId: ID!
    $productId: ID
    $fromDate: ISODate
    $toDate: ISODate
    $limit: Int
    $sortBy: String
  ) {
    orderItemsNew(
      businessId: $businessId
      productId: $productId
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
      sortBy: $sortBy
    ) {
      nodes {
        id
        productId
        netAmountPerUnit
        associatedIntegralItemsPricePerUnit
        totalNetAmount
        discountAmount
        product {
          id
          name
          sku
        }
        orderId
        orderDate
        orderDiscountRate
        orderDiscountAmount
        price
        quantity
        discount
        items {
          id
          productId
          netAmountPerUnit
          totalNetAmount
          type
          product {
            id
            name
            sku
          }
          orderId
          orderDate
          price
          quantity
          discount
          netPrice
          totalAmount
          difference {
            price {
              customerValue
              supplierValue
              solved
            }
            discount {
              customerValue
              supplierValue
              solved
            }
            netPrice {
              customerValue
              supplierValue
              solved
            }
          }
          source {
            price {
              type
              ref
            }
            discount {
              type
              ref
            }
          }
          orderSource {
            type
            ref
          }
        }
        netPrice
        totalAmount
        difference {
          price {
            customerValue
            supplierValue
            solved
          }
          discount {
            customerValue
            supplierValue
            solved
          }
          netPrice {
            customerValue
            supplierValue
            solved
          }
        }
        source {
          price {
            type
            ref
          }
          discount {
            type
            ref
          }
        }
        orderSource {
          type
          ref
          document {
            id
            type: newType
            documentNumber
          }
        }
      }
    }
  }
`;
