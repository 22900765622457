import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useEventDifferences(variables) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(EVENT_DIFFERENCES_QUERY, variables, () => ({
    enabled: !!variables.value.customerId,
  }));

  const eventDifferences = computed(() => {
    const deliveryDifferences = result.value ? result.value.deliveryDifferences?.nodes ?? [] : [];
    const orderDifferences = result.value ? result.value.orderDifferences?.nodes ?? [] : [];

    return {
      deliveryDifferences,
      orderDifferences,
    };
  });

  onError((err) => {
    console.error('useEventDifferences', err);
    error();
  });

  return {
    eventDifferences,
    loading,
    refetch,
  };
}

const EVENT_DIFFERENCES_QUERY = gql`
  query eventDifferences($customerId: ID) {
    deliveryDifferences(customerId: $customerId) {
      nodes {
        id
        date
        supplierId
        products {
          productId
          quantity
        }
        source {
          ref
        }
        eventReferences {
          reference
          documentId
        }
      }
    }
    orderDifferences(customerId: $customerId) {
      nodes {
        id
        date
        supplierId
        customerId
        netAmount
        products {
          productId
          id
          discount
          price
          totalAmount
          quantity
          product {
            sku
            name
          }
        }
        itemsCount {
          ordered
          returnNotice
        }
        source {
          type
          ref
        }
        rounding
        eventReferences {
          reference
          documentId
          document {
            type
          }
        }
      }
    }
  }
`;
