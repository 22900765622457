import { DateTime } from 'luxon';

import i18n from '@/imports/startup/client/i18n';
import { PERIOD_OPTIONS } from './constants';

export const getAvailableCompareOptions = (option) => {
  switch (option) {
    case PERIOD_OPTIONS.CURRENT_MONTH:
      return [PERIOD_OPTIONS.LAST_MONTH, PERIOD_OPTIONS.MONTHLY_AVERAGE, PERIOD_OPTIONS.SAME_MONTH_LAST_YEAR];
    case PERIOD_OPTIONS.LAST_MONTH:
      return [PERIOD_OPTIONS.LAST_MONTH_LAST_YEAR, PERIOD_OPTIONS.MONTHLY_AVERAGE];
    case PERIOD_OPTIONS.MONTHLY_AVERAGE:
      return [PERIOD_OPTIONS.CURRENT_MONTH, PERIOD_OPTIONS.LAST_MONTH];
    case PERIOD_OPTIONS.CURRENT_YEAR:
      return [PERIOD_OPTIONS.LAST_YEAR];
    case PERIOD_OPTIONS.LAST_YEAR:
      return [PERIOD_OPTIONS.CURRENT_YEAR];
    case PERIOD_OPTIONS.ALL_TIME:
    case null:
    default:
      return [];
  }
};

export const getGraphPeriod = (selectedPeriodOptionForGraph) => {
  const dates = getAggregationDateRange(selectedPeriodOptionForGraph);
  if (!dates) return null;

  return {
    fromDate: DateTime.fromJSDate(dates.fromDate).toISODate(),
    toDate: DateTime.fromJSDate(dates.toDate).toISODate(),
  };
};

const getAggregationDateRange = (option) => {
  let fromDate;
  let toDate;

  switch (option) {
    case PERIOD_OPTIONS.CURRENT_MONTH:
      fromDate = DateTime.now().startOf('month');
      toDate = DateTime.now();
      break;
    case PERIOD_OPTIONS.LAST_MONTH:
      fromDate = DateTime.now().minus({ months: 1 }).startOf('month');
      toDate = DateTime.now().minus({ months: 1 }).endOf('month');
      break;
    case PERIOD_OPTIONS.MONTHLY_AVERAGE:
      fromDate = DateTime.now().minus({ years: 1 });
      toDate = DateTime.now();
      break;
    case PERIOD_OPTIONS.CURRENT_YEAR:
      fromDate = DateTime.now().startOf('year');
      toDate = DateTime.now();
      break;
    case PERIOD_OPTIONS.LAST_YEAR:
      fromDate = DateTime.now().minus({ years: 1 }).startOf('year');
      toDate = DateTime.now().minus({ years: 1 }).endOf('year');
      break;
    case PERIOD_OPTIONS.SAME_MONTH_LAST_YEAR:
      fromDate = DateTime.now().minus({ years: 1 }).startOf('month');
      toDate = DateTime.now().minus({ years: 1 }).endOf('month');
      break;
    case PERIOD_OPTIONS.LAST_MONTH_LAST_YEAR:
      fromDate = DateTime.now().minus({ years: 1, months: 1 }).startOf('month');
      toDate = DateTime.now().minus({ years: 1, months: 1 }).endOf('month');
      break;
    case PERIOD_OPTIONS.ALL_TIME:
      return {};
    case null:
    default:
      return null;
  }

  return { fromDate: fromDate.toJSDate(), toDate: toDate.toJSDate() };
};

export const getPeriodOptions = (convertPeriodOptionToSelectOption) =>
  Object.values(PERIOD_OPTIONS)
    .filter(
      (option) => option !== PERIOD_OPTIONS.SAME_MONTH_LAST_YEAR && option !== PERIOD_OPTIONS.LAST_MONTH_LAST_YEAR
    )
    .map(convertPeriodOptionToSelectOption);

export const convertPeriodOptionToSelectOption = (option) => {
  if (option === PERIOD_OPTIONS.SAME_MONTH_LAST_YEAR || option === PERIOD_OPTIONS.LAST_MONTH_LAST_YEAR) {
    return {
      label: DateTime.local()
        .minus({ years: 1, months: Number(option === PERIOD_OPTIONS.LAST_MONTH_LAST_YEAR) })
        .toJSDate()
        .toLocaleDateString(i18n.locale, { month: 'short', year: '2-digit' }),
      value: option,
    };
  }

  return {
    label: i18n.t(`terms.periodOption.${option}`),
    value: option,
  };
};
