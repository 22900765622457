import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { onUnmounted } from 'vue';

const onDoneSubscribedCallbacks = [];
const onErrorSubscribedCallbacks = [];

export const useOrderCreate = () => {
  const { mutate, loading, onDone, onError } = useMutation(ORDER_CREATE_MUTATION);
  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  const onDoneCallbacks = [];
  const onErrorCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
    onErrorCallbacks.forEach((cb) => onErrorSubscribedCallbacks.splice(onErrorSubscribedCallbacks.indexOf(cb), 1));
  });

  return {
    createOrder: mutate,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
    onError: (callback) => {
      onErrorCallbacks.push(callback);
      onErrorSubscribedCallbacks.push(callback);
    },
  };
};

export const ORDER_CREATE_MUTATION = gql`
  mutation orderCreate($createParams: OrderCreateInput!) {
    orderCreate(createParams: $createParams) {
      id
    }
  }
`;
