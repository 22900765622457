export const PERIOD_OPTIONS = {
  CURRENT_MONTH: 'currentMonth',
  LAST_MONTH: 'lastMonth',
  MONTHLY_AVERAGE: 'monthlyAverage',
  CURRENT_YEAR: 'currentYear',
  LAST_YEAR: 'lastYear',
  ALL_TIME: 'allTime',
  SAME_MONTH_LAST_YEAR: 'sameMonthLastYear',
  LAST_MONTH_LAST_YEAR: 'lastMonthLastYear',
};
