import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { onUnmounted } from 'vue';

const onDoneSubscribedCallbacks = [];
const onErrorSubscribedCallbacks = [];

export const useOrderItemDelete = () => {
  const { mutate, onDone, onError } = useMutation(ORDER_ITEM_DELETE_MUTATION);
  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  const onDoneCallbacks = [];
  const onErrorCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
    onErrorCallbacks.forEach((cb) => onErrorSubscribedCallbacks.splice(onErrorSubscribedCallbacks.indexOf(cb), 1));
  });

  return {
    deleteOrderItem: mutate,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
    onError: (callback) => {
      onErrorCallbacks.push(callback);
      onErrorSubscribedCallbacks.push(callback);
    },
  };
};

export const ORDER_ITEM_DELETE_MUTATION = gql`
  mutation orderItemDelete($orderId: ID!, $orderItemId: ID!) {
    orderItemDelete(orderId: $orderId, orderItemId: $orderItemId)
  }
`;
